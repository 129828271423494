<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card class="mb-4" body-class="d-flex flex-row justify-content-center" v-if="currentUserCan(permissions.CAN_VIEW_ENERGY_PAYMENT_REPORT)">
          <div class="flex-fill">
            <h4 class="card-title">Payment Report</h4>
            <h6 class="card-subtitle font-weight-light text-muted">An overview of payments for a given timeframe.</h6>
          </div>
          <b-button class="align-self-center ml-2" to="/energy/reports/payments" variant="secondary">View Report<b-icon class="ml-2" icon="arrow-right" /></b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import currentUser from '../../mixins/currentUser'

export default {
  mixins: [currentUser]
}
</script>

<style>

</style>
